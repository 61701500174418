<template>
  <div class="app-wrapper">
    <el-table
      :data="tableData"
      :default-sort = "{prop: 'points', order: 'descending'}"
      ref="table"
    >
      <el-table-column
        label="Platz"
        type="index"
        width="55"
      />
      <el-table-column
        prop="name"
        label="Name"
      />
      <el-table-column
        prop="points"
        label="Punkte"
        sortable
        width="55"
      />
      <el-table-column
        label="Buttons"
        width="120"
      >
        <template #default="scope">
          <el-button type="success" icon="el-icon-caret-top"
            size="mini"
            circle
            @click="up(scope.$index, scope.row)"
          />
          <el-button type="danger" icon="el-icon-caret-bottom"
            size="mini"
            circle
            @click="down(scope.$index, scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    tableData: [
      {
        name: 'Michael',
        points: 0,
      },
      {
        name: 'Daniel & Jenny',
        points: 0,
      },
      {
        name: 'Melanie',
        points: 0,
      },
      {
        name: 'Thommy',
        points: 0,
      },
      {
        name: 'Manuel',
        points: 0,
      },
    ],
  }),

  methods: {
    up(index, row) {
      const dataRow = this.tableData.find((item) => row.name === item.name);
      dataRow.points += 1;
      this.sortTable();
      this.$refs.table.sort({ property: 'points', order: 'descending' });
      this.$nextTick()
        .then(() => {
        });
    },
    down(index, row) {
      const dataRow = this.tableData.find((item) => row.name === item.name);
      dataRow.points -= 1;
    },
    sortTable() {
      document.querySelector('.sort-caret.descending').click();
      setTimeout(document.querySelector('.sort-caret.descending').click(), 500);
      // const arr = [];
      // const $rows = document.querySelectorAll('.el-table__row');
      // $rows.forEach(($row, index) => {
      //   const name = $row.querySelector('td:nth-child(2)').textContent;
      //   arr.push({ index, name });
      // });
      // console.log(arr);
    },
  },
};
</script>

<style lang="scss">
.el-table::before {
  background-color: transparent;
}
.el-table {
  width: 60vw;
  max-width: 500px;
  margin: auto;

  background-color: transparent;

  font-weight: bold;
  font-size: 18px;
  color: #000;

  .el-table__header-wrapper {
    display: none;
  }

  .el-table__body {
    border-spacing: 10px 20px;
    table-layout: auto;
  }

  .el-table__row {
    td {
      border: 2px solid #2e2e2e;

      background-color: #acacac;
    }
    td:first-child,
    td:nth-child(3),
    td:last-child {
      text-align: center;
    }
  }

  &.el-table--enable-row-hover .el-table__body {
    tr:hover > td {
      background-color: #acacac;
    }
    tr:first-child:hover > td {
      background-color: #b6ff8b;
    }
    tr:nth-child(2):hover > td {
      background-color: #fff78d;
    }
  }

  .el-table__row:first-child {
    td {
      border-color: #5cff3c;
      background-color: #b6ff8b;
    }
  }

  .el-table__row:nth-child(2) {
    td {
      border-color: #FFE600;
      background-color: #fff78d;
    }
  }
}
</style>
