import { createApp } from 'vue';
import Vuex from 'vuex';
import {
  ElTable,
  ElTableColumn,
  ElButton,
  ElIcon,
} from 'element-plus';
import App from './App.vue';
import router from './router';

const app = createApp(App).use(router);

app.use(ElTable);
app.use(ElTableColumn);
app.use(ElButton);
app.use(ElIcon);
app.use(Vuex);

app.mount('#app');
